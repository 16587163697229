// Step 1: Import React
import * as React from 'react'
import Layout from '../components/layout'
import Seo  from '../components/seo'
import { GatsbyImage,getImage } from 'gatsby-plugin-image'
import { graphql } from 'gatsby'
import {mainContainer,
        entry,
        intermediateContainer,
      village,
    villageText,end} from '../components/css/about.module.css'


// Step 2: Define your component
const AboutPage = ({data}) => {
  console.log(data)
  return (
    <Layout pageTitle="Hakkımızda">
      <div className={mainContainer}>
        <h1 className={entry}>Emine Yavuz Mimarlık, sahip olduğu teorik ve pratik birikimlerinin ışığında doğal ve kültürel miras varlıklarının korunmasında hizmet vermektedir.</h1>
        <GatsbyImage image={getImage(data.about.edges[0].node)} alt='about'/>
        <div className={intermediateContainer}>
        <p>Yavuz Mimarlık, 2009 yılında Emine Yavuz tarafından kurulmuştur. Türkiye, Kocaeli merkezli, ülkemizin tüm bölgelerinde tarihi ve kültürel miras alanlarının korunmasına yönelik çözümler sunmaktadır. Son derece işbirliği ve araştırmaya dayalı çalışma yöntemimiz, koruma sürecinin başından itibaren çeşitli alanlarda paydaş ve uzmanları içermektedir. Sonuç olarak, geçmişin izini taşıyan unsurlara gelecek planı sunan bütüncül nitelikte projeler ortaya çıkmaktadır. 2009 yılında Yavuz Mimarlık olarak başlayan faaliyetimiz, 2021 yılında kapsam alanını genişleterek Emine Yavuz Mimarlık olarak hizmet vermeye devam etmektedir.</p>
        <p>Yavuz Mimarlık tarafından geliştirilen projeler, her tür yapı gruplarına kadar çeşitlilik göstermektedir. Tamamlanan projeler arasında Doğu Roma Dönemi, İstanbul’un en eski dini yapısı olan Studios Manastırı Kilisesi; Osmanlı İmparatorluğu’nun son saray yapılarından Yıldız Sarayı Çit Kasrı yapısı; 1461 Trabzon’un fethinden sonra Ortahisar Fatih Büyük Cami olarak anılan üç nefli bazilikal plan formuna sahip Altunbaşlı Bakire Kilisesi yer almaktadır. Proje işlerinin dışında İznik Lefke Kapı Surları, Bilecik Hükümet Konağı, Bursa Türk İslam Eserleri Müzesi ve çeşitli sivil mimarlık eserlerinin restorasyon uygulamaları da yer almaktadır.</p>
        <div className={village}>
        <GatsbyImage image={getImage(data.village.edges[0].node)} alt="village"/>
        <p className={villageText}>"Giyotin pencere olduğunu öğrenecektim yıllar sonra,  
            doğduğum, çocukluk ve gençlik yıllarımı yaşadığım, en güzel düşlere yol aldığım, 
            hayal kırıklıklarımda, yeşilliklerle avunmamı sağlayan iki oda evimizden, 
            dağları gören penceremizin… Geçmişin en derin izlerini ruhuma taşıyan o küçücük pencereydi belki de 
            mimar olma isteğimin oluşmasına sebep... Belki de ruhunun olduğuna inandığım, her taşında, 
            her ahşabında bin bir hikaye barındıran o ev… Belki de geçmişle bağımı koparmakta  
            bir o kadar isteksizdim de ben taşları, pencereyi dile gelmiş zannederdim zaman zaman…"</p>
        </div>
        <p>1987 yılında mezun olduğum Karadeniz Teknik Üniversitesi Mimarlık Fakültesi bölümünden sonra, 1987 yılında göreve başladığım Kültür ve Turizm Bakanlığı bünyesindeki koruma kurulları, raportör, ardından idareci, Rölöve Anıtlar Müdürlüğünde kontrol mühendisliği görevlerim hep tarihi yapılara olan hassasiyetimi daha da körükledi. Tanıdıkça, gördükçe, yaşadıkça ayrılmaz bir bağ oldu aramızda… Öyle ki devlet memuriyetinden emekli olur olmaz Yavuz Mimarlık adı altında devam ettiğim tarihi ve kültürel yapılara yönelik mimarlık hayatımda da tek çabam dertleşebilmekti o ruha sahip yüzlerce tarihi eser taşınmazlarla…</p>
        <p>Benim gibi, gerçekten taşınmaz kültür varlıklarıyla, tarihle, mimariyle gönül bağı olan gönül bağı oluşturan çok değerli, konusunda uzman bir ekip oluşturduk, üstlendiğimiz her projede bu konudaki tecrübe ve birikimiyle sonsuz katkısı olan sevgili eşim Hüseyin Kazım Yavuz ve proje müdürümüz Muammer Altundaş vazgeçemeyeceklerimizden…</p>
        <div className={end}><h5>Emine Yavuz</h5></div>
        </div>
      </div>
    </Layout>
  )
}

export const Head = () => <Seo title='Hakkımızda' />

// Step 3: Export your component
export default AboutPage
export const pageQuery = graphql`
query {
  about:allFile(filter: {name: {eq: "about"}}) {
    edges {
      node {
        childImageSharp {
          gatsbyImageData(
            height: 600
            placeholder: BLURRED
            blurredOptions: {width: 100}
            quality: 100
            layout: FULL_WIDTH
            transformOptions: {fit: CONTAIN}
            sizes: "100"
          )
        }
      }
    }
  }
  village:allFile(filter: {name: {eq: "village"}}) {
    edges {
      node {
        childImageSharp {
          gatsbyImageData(

            placeholder: BLURRED
            blurredOptions: {width: 100}
            quality: 100
            layout: CONSTRAINED
            transformOptions: {fit: INSIDE}

          )
        }
      }
    }
  }
}
`